@import url('../../utils/constants.css');

.productDetail{
	align-items: left;
	justify-content: flex-start;
}

.path-name-container{
	display: flex;
	align-items: flex-start;
}

.path-name {
	font-size: 1.1rem;
	color: var(--primary);
}

.path-name.path:hover {
	text-decoration: underline;
}

.product-photos {
	width: 30vw;
	max-width: 400px;
	min-width: 90px;
}

.product-detail-container {
	display: flex;
	margin-top: 1rem;
}

.product-description-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 0 1.5rem;
	color: var(--primary);
	align-items: flex-start;
}

.product-title {
	font-size: 1.7rem;
}

.product-description-container .product-id {
	font-size: 0.9rem;
	color: darkgray;
}

.product-price {
	margin-top: 20px;
	font-weight: 500;
	font-size: 1.2rem;
}

.product-description {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
}
.product-description .ingredient-item {
	margin-left: 6px;
}

.product-description-container .qty-selector {
	/* background-color: #d7d7d7; */
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	width: 140px;
	height: 50px;
	align-items: center;
	justify-content: center;
}

.product-description-container .qty-selector .btn {
	width: 25%;
	height: 100%;
	font-size: 1.5rem;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-description-container .qty-selector .btn:hover {
	cursor: pointer;
}

.product-description-container .qty-selector .btn .text:hover {
	cursor: pointer;
}

.product-description-container .qty-selector .qty {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	border: 1px var(--primary) solid;
}

.add-to-cart-btn {
	color: white;
	background-color: var(--primary);
	padding: 5px 10px;
	text-align: center;
	margin-top: 20px;
	width:100%
}

.add-to-cart-btn:hover{
	cursor: pointer;
}
