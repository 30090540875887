.app {
	text-align: center;
	min-height: calc(
		100vh - var(--footerHeight) - var(--headerHeight) -
			var(--headerBottomMargin)
	);
	padding: 0 var(--rootPadding);
}

@media (min-width: 1500px) {
	.app {
		padding: 0 22vw;
	}
}
