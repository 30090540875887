@import url('../../utils/constants.css');

.cartPage .title{
		margin-top: 5vh;
	text-align: left;
	margin-left:10px;
	font-weight: 600;
	color: var(--primary);
	font-size: 1.5rem;
}

.cart-container {
margin-top: 1vh;
}

.cart-item-container {
	display: flex;

}

.cart-item-box{
	display: flex;
	padding:1.5vw 3vw;
	align-items: center;
	border-bottom: var(--primary) 2px solid;

}

.cart-item-box .product-photo{
	width: 15vw;
	max-width: 200px;
	min-width: 90px;
}

.cart-item-container .product-id{
	flex:1
}

.cart-item-box .qty-selector {
	/* background-color: #d7d7d7; */
	display: flex;
	flex-direction: row;
	width: 100px;
	height: 30px;
	align-items: center;
	justify-content: center;
}

.cart-item-box .qty-selector .btn {
	width: 25%;
	height: 100%;
	font-size: 1.2rem;
	font-weight: 400;
		color:gray;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cart-item-box .qty-selector .btn:hover {
	cursor: pointer;
}

.cart-item-box .qty-selector .btn .text:hover {
	cursor: pointer;
}


.cart-item-box .qty-selector .qty {
	font-size: 1rem;
	padding: 2px;
}