p {
	margin: 0;
}

/* .App-header {
	background-color: white;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
} */

.Cookies .title {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	font-size: 1.6rem;
	text-align: left;
	border-bottom: var(--primary) 1.5px solid;
	color: var(--primary);
}

.products-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.product-container {
	width: 25%;
	max-width: 280px;
	min-width: 130px;
	margin: 2%;
}

.Cookies .product-thumbnail {
	width: 100%;
}

.product-container .label {
	@media (min-width: 900px) {
		font-size: 19px;
	}

	@media (max-width: 899px) {
		font-size: 15px;
	}
}
