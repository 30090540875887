header {
	display: flex;
	flex-direction: column;
	/* margin-right: var(--rootPadding);
	margin-left: var(--rootPadding); */
	margin-bottom: 30px;
}

.row1-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.logo-container {
	display: flex;
	width: 450px;
	height: fit-content;
	z-index: 20;

	@media (min-width: 900px) {
		width: 450px;
	}

	@media (max-width: 899px) {
		width: 220px;
	}
	@media (max-width: 500px) {
		width: 190px;
	}
}

.logo {
	width: 100%;
}

.nav-btn-container {
	display: flex;
	height: fit-content;
}

.nav-btn {
	display: flex;
	height: fit-content;
	margin-left: 30px;

	@media (min-width: 900px) {
		width: 60px;
	}
	@media (max-width: 899px) {
		width: 40px;
	}
	@media (max-width: 500px) {
		width: 32px;
		margin-left: 15px;
	}
}

.nav-btn .circle-button {
	width: 100%;
	height: fit-content;
}

.badge {
	padding-left: 9px;
	padding-right: 9px;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}

#CartCount {
	font-size: 13px;
	background: var(--third);
	color: var(--primary);
	padding: 1px 6px;
	vertical-align: top;
	margin-left: -12px;
	vertical-align: top;
	height: fit-content;
	@media (max-width: 899px) {
		font-size: 10px;
		padding: 1px 3.5px;
	}
}

.row2-container {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: end;
	background-color: var(--primary);

	@media (min-width: 900px) {
		margin-top: -50px;
	}

	@media (max-width: 899px) {
		margin-top: -30px;
	}

	@media (max-width: 500px) {
		margin-top: -30px;
	}
}

.row2-container .nav-link {
	color: var(--secondary);
	padding: 0 20px;
	border-left: var(--third) 2px solid;
	font-size: 22px;

	@media (max-width: 899px) {
		display: none;
	}
}

.row2-container .first {
	border: none;
}

.row2-container .current-page {
	color: var(--third);
}

.bar {
	color: var(--secondary);
	margin-right: 10px;
	font-size: 17px;
	padding: 3px;

	@media (min-width: 900px) {
		display: none;
	}
}

.mobile-row2-container {
	@media (min-width: 900px) {
		display: none;
	}
	@media (max-width: 899px) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		position: fixed;
		top: 0;
		right: -300px;
		height: 100vh;
		width: clamp(130px, 40vw, 200px);
		background-color: var(--primary);
		box-shadow: 0 40px 60px black;
		padding: 20px 20px;
		transition: 0.8s;
		z-index: 20;
	}
}

.menuShown {
	right: 0px;
}
.mobile-row2-container .close {
	color: var(--secondary);
	font-size: 19px;
	align-self: flex-end;
	margin-bottom: 15px;
}
.mobile-row2-container .nav-link {
	color: var(--secondary);
	padding: 5px 10px;
	border-left: var(--third) 2px solid;
	font-size: 15px;
}

.mobile-row2-container .current-page {
	color: var(--third);
}
