@import url('/src/index.css');

footer {
	background-color: var(--primary);
	/* height: var(--footerHeight); */
	min-height: var(--footerHeight);
	display: flex;
	align-items: center;
	padding: 1vh 5vw;
	justify-content: space-between;
	color: white;
	@media (min-width: 1500px) {
		padding: 1vh 22vw;
	}
}

footer .menu-container {
	display: flex;
	height: 100%;
	justify-content: center;
	flex: 1;
	justify-content: right;
	align-items: center;
	margin-right: 2vw;
}

.menu-container .label {
	margin: 0 1.5vw;
}

footer .contact-us-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
}

.contact-us-container .item-container {
	display: flex;
	margin-top: 5px;
	align-items: center;
}

footer .label {
	color: white;
	margin: 0;
}

footer i {
	color: white;
	margin-right: 5px;
}
