p {
	margin: 0;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

/* .App-header {
	background-color: white;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
} */
.banner {
	width: 100%;
	height: clamp(150px, 30vw, 450px);
	background-color: var(--primary);
	display: flex;
	background-image: url('/src/assets/banner.png');
	background-size: auto 100%;
	background-repeat: no-repeat;
	margin-bottom: 4vw;
}

.banner .left {
	width: 50%;
}

.banner .right {
	padding: 5%;
	align-self: center;
	text-align: end;
	width: 50%;
	color: white;
	font-size: 1.4rem;
	/* font-family: var(--fontSlogan); */
	font-weight: 100;
}

.AllSorts .title-container {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	border-bottom: var(--primary) 1.5px solid;
	color: var(--primary);
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	padding: 0 2.5rem;
}

.AllSorts .title-container .title {
	@media (min-width: 900px) {
		font-size: 26px;
	}

	@media (max-width: 899px) {
		font-size: 18px;
	}
}

.AllSorts .title-container .button {
	@media (min-width: 900px) {
		font-size: 18px;
	}

	@media (max-width: 899px) {
		font-size: 14px;
	}
	color: var(--primary);
}

.products-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.product-container {
	width: 25%;
	max-width: 280px;
	min-width: 130px;
	margin: 2%;
}

.AllSorts .product-thumbnail {
	width: 100%;
}

.product-container .label {
	@media (min-width: 900px) {
		font-size: 19px;
	}

	@media (max-width: 899px) {
		font-size: 15px;
	}
}
