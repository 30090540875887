.login {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(
		100vh - var(--footerHeight) - var(--headerHeight) -
			var(--headerBottomMargin)
	);
	padding: 0 var(--rootPadding);
}

h1 {
	font-weight: bold;
	margin: 0;
	font-family: 'Montserrat', sans-serif;
}

h2 {
	text-align: center;
	font-family: 'Montserrat', sans-serif;
}

p {
	font-size: 0.8rem;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
	font-family: 'Montserrat', sans-serif;
}

span {
	font-size: 0.7rem;
	margin: 10px 0;
}
span:before,
span:after {
	background-color: #000;
	content: '';
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
}
span:before {
	right: 0.5em;
	margin-left: -50%;
	width: 50px;
}
span:after {
	left: 0.5em;
	margin-right: -50%;
	width: 50px;
}
a {
	color: #333;
	font-size: 0.8rem;
	text-decoration: none;
	margin: 15px 0;
	font-family: 'Montserrat', sans-serif;
}

button {
	border-radius: 20px;
	border: 1px solid var(--primary);
	background-color: var(--primary);
	color: #ffffff;
	font-size:13px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	cursor:pointer
}

.button {
	margin-top: 15px;
	width: fit-content;
	align-self: center;
}

.white-text-button{
	color:white
}

.link{
	font-size: 12px;
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #ffffff;
}

.form {
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}


.input-form {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.confirm-account-form {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	padding-top: 20%;
	padding-bottom: 20%;
}

.back-to-sign-up-button{
	color: var(--primary);
	text-align: left;
	margin-bottom: 10%;
		cursor: pointer;
}

input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
	caret-color: var(--primary);
}

input:focus {
	outline: solid 2px var(--primary);
	border-radius: 2px;
}

.container {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
}

.error-msg {
	color: red;
	width: 100%;
	text-align: left;
	font-size: 13px;
}

.sign-in-send-code-btn{
	font-size: 13px;
	color:white;
	background-color: var(--primary);
	padding:12px 5%;
	border-radius: 5px;
}

.sign-in-code-input{
	width:fit-content;
	flex:1;
	margin-right: 8px;
}

.pw-req-text{
	font-size: 12px;
	text-align: left;
}

@media (max-width: 799px) {
	.sign-in-container {
		left: 100%;
		width: 100%;
		z-index: 2;
		animation: show 0.4s;
		transform: translateX(-100%);
	}
	.mobile-signup-button {
		background-color: transparent;
		margin-top: 15px;
		color: var(--primary);
		padding: 11px 43px;
	}
	.sign-up-container {
		left: 100%;
		width: 100%;
		z-index: 0;
	}
	.container.mobile-signup-form-active .sign-up-container {
		z-index: 3;
		animation: show 0.4s;
		transform: translateX(-100%);
	}
}
@media (min-width: 799px) {
	.sign-in-container {
		left: 0;
		width: 50%;
		z-index: 2;
	}
	.mobile-signup-button {
		display: none;
	}
	.sign-up-container {
		left: 0;
		width: 50%;
		opacity: 0;
		z-index: 1;
	}
	.container.right-panel-active .sign-in-container {
		transform: translateX(100%);
	}

	.container.right-panel-active .sign-up-container {
		transform: translateX(100%);
		opacity: 1;
		z-index: 5;
		animation: show 0.6s;
	}
}
.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

@keyframes show {
	0%,
	49.99% {
		opacity: 0;
		z-index: 1;
	}

	50%,
	100% {
		opacity: 1;
		z-index: 5;
	}
}
@media (max-width: 799px) {
	.overlay-container {
		display: none;
	}
}
@media (min-width: 799px) {
	.overlay-container {
		position: absolute;
		top: 0;
		left: 50%;
		width: 50%;
		height: 100%;
		overflow: hidden;
		transition: transform 0.6s ease-in-out;
		z-index: 10;
	}
}

.container.right-panel-active .overlay-container {
	transform: translateX(-100%);
}

.overlay {
	background: var(--primary);
	background: -webkit-linear-gradient(
		to right,
		var(--primary),
		var(--primary)
	);
	background: linear-gradient(to right, var(--primary), var(--primary));
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #ffffff;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	padding: 15% 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin-top: 10px;
}

.social-container a {
	border: 1px solid #dddddd;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}
