.column{
    margin:30px 0;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;

}

.button{
    color:var(--primary);
}

.button:hover{
    cursor: pointer;

}

.payment-method-item{
    margin-right: 10px;
}