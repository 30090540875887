:root {
	--headerHeight: 8vh; /* must be vh, exported to Header.js for cal */
	--headerBottomMargin: 0.9rem;
	--footerHeight: 9vh;
	--primary: #54656e;
	--secondary: rgb(230, 230, 230);
	--third: #db7425;
	--rootPadding: 5vw;
	--fontSlogan: 'Nanum Gothic', sans-serif;
	--fontTitle: 'Castoro Titling', cursive;
}
