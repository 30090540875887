* {
	box-sizing: border-box;
}

div:hover {
	cursor: default;
}

div {
	caret-color: transparent;
}
@media (max-width: 799px) {
	html {
		font-size: 8px;
	}
}
@media (min-width: 799px) {
	html {
		font-size: 20px;
	}
}

body {
	margin: 0;
	height: 100vh;
	width: 100vw;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--secondary);
	/* display: flex; */
	/* flex: 1; */
	width: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	text-decoration: none;
}

i {
	color: var(--primary);
}

p {
	margin: 0;
}
